import { FC, memo } from 'react';
import { canonicalLink, getLinkbyQrType } from 'utils/seo';
import { useTranslation } from 'react-i18next';

import { useQrCode } from 'hooks/useQrCode';
import { QRCodeDTO } from 'api/qrCode/types';
import { QRWrapper } from 'components/QRCodes/PopupManager/QrPreviewPopup/styled';
import TranslationSpan from 'components/ui/TranslationSpan';
import { Container, Text } from './styled';
import { LINKS } from 'constants/constants';
import FooterLink from '../QRTemplates/shared/FooterLink';

type QrCodeScanProps = {
  qrCode: QRCodeDTO;
};

const QrCodeScan: FC<QrCodeScanProps> = ({ qrCode }) => {
  const { qrCodeTemplate } = useQrCode(qrCode);
  const { t, i18n } = useTranslation();
  const codeType = qrCode.content?.type;
  const SEOTitles = {
    url: t('qr.scan.url.title', 'Link QR Code generated by My QR Code'),
    vCard: t('qr.scan.vCard.title', 'VCard QR Code generated by My QR Code'),
    businessPage: t('qr.scan.businessPage.title', 'Business QR Code generated by My QR Code'),
    app: t('qr.scan.app.title', 'App QR Code generated by My QR Code'),
    video: t('qr.scan.video.title', 'Video QR Code generated by My QR Code'),
    menu: t('qr.scan.menu.title', 'Restaurant Menu QR Code generated by My QR Code'),
    pdf: t('qr.scan.pdf.title', 'PDF QR Code generated by My QR Code'),
    facebook: t('qr.scan.facebook.title', 'Facebook QR Code generated by My QR Code'),
    plainText: t('qr.scan.plainText.title', 'Text QR Code generated by My QR Code'),
    wifi: t('qr.scan.wifi.title', 'WiFi QR Code generated by My QR Code'),
    images: t('qr.scan.images.title', 'Images QR Code generated by My QR Code'),
    socialMedia: t('qr.scan.socialMedia.title', 'Social Media QR Code generated by My QR Code'),
  };
  let pageTitle = t('qr.scan.default.title', 'QR Code generated by My QR Code');
  let canonicalURL = LINKS.BUILDER_BASE;

  if (codeType) {
    canonicalURL = canonicalLink(getLinkbyQrType(qrCode.content?.type), false, i18n.language);
    pageTitle = SEOTitles[codeType];
  }

  return (
    <Container>
      <QRWrapper>
        <img src={qrCodeTemplate} draggable="false" alt={`${t('qr.scan.alt', 'Scan this')} ${pageTitle}`} />
      </QRWrapper>
      <Text>
        <TranslationSpan phrase="qr.shared.scan.qr" fallback="Scan the QR code" />
      </Text>
      <FooterLink linkTo={canonicalURL} disabled={qrCode.userSettingsJson.hideFooter} />
    </Container>
  );
};

export default memo(QrCodeScan);
